import React from 'react';

const HelpIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`help-icon ${isAnimating ? 'animate' : ''}`}
    >
      <path 
        d="M16.2998 5.93262C17.8998 8.40762 17.899 11.5943 16.3007 14.0685" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M12.956 7.04363C14.5887 8.67635 14.5887 11.3235 12.956 12.9562C11.3233 14.5889 8.67611 14.5889 7.04339 12.9562C5.41067 11.3235 5.41067 8.67635 7.04339 7.04363C8.67611 5.41091 11.3233 5.41091 12.956 7.04363Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M11.9341 6.29257L14.0874 3.67674C14.4007 3.2959 14.9741 3.2684 15.3232 3.61674L16.3841 4.67757C16.7332 5.02674 16.7049 5.60007 16.3241 5.9134L13.7082 8.06674" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M8.06585 13.7076L5.91251 16.3233C5.59918 16.7042 5.02585 16.7318 4.67668 16.3834L3.61585 15.3225C3.26668 14.9733 3.29501 14.4001 3.67585 14.0868L6.29168 11.9333" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M6.29267 8.06585L3.676 5.91251C3.29517 5.59918 3.26767 5.02585 3.616 4.67668L4.67683 3.61585C5.026 3.26668 5.59933 3.29501 5.91267 3.67585L8.066 6.29168" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M13.7073 11.9341L16.3231 14.0874C16.704 14.4007 16.7315 14.9741 16.3831 15.3232L15.3223 16.3841C14.9731 16.7332 14.3998 16.7049 14.0865 16.3241L11.9331 13.7082" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M14.0685 16.3009C11.5943 17.8992 8.40678 17.9 5.93262 16.3" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M3.69944 5.93164C2.10111 8.40581 2.10028 11.5925 3.70028 14.0675" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M14.0675 3.70004C11.5925 2.10004 8.40581 2.10087 5.93164 3.6992" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpIcon; 