import React, { useState, useEffect, useRef } from 'react';
import './NavDropdown.css';

const NavDropdown = ({ link, isOpen, onMouseEnter, onMouseLeave }) => {
  const [showPanel, setShowPanel] = useState(false);
  const timerRef = useRef(null);
  
  const handleMouseEnter = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setShowPanel(true);
    }, 200);
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setShowPanel(false);
    }, 200);
    onMouseLeave();
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div 
      className="nav-dropdown-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="navbar-link">
        {link.text}
        <span className={`caret ${showPanel ? 'rotated' : ''}`}>▾</span>
      </div>
      
      <div className={`nav-dropdown-panel ${showPanel ? 'show' : ''}`}>
        {link.submenu.sections ? (
          // Handle sectioned submenu
          link.submenu.sections.map((section, idx) => (
            <div key={idx} className="nav-dropdown-section">
              <div className="nav-dropdown-section-title">{section.title}</div>
              <div className="nav-dropdown-section-content">
                {section.items.map((item, itemIdx) => (
                  <div key={itemIdx} className="dropdown-item">
                    <div className="dropdown-item-content">
                      <h3>{item.text}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          // Handle regular submenu
          <div className="dropdown-grid">
            {link.submenu.map((item, idx) => (
              <div key={idx} className="dropdown-item">
                <div className="dropdown-item-content">
                  <h3>{item.text}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavDropdown;
