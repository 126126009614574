import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabaseService } from '../services/supabase-service';
import './employer-signup.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFinchConnect } from '@tryfinch/react-connect';
import { useAuth } from '../contexts/AuthContext';

const formatEIN = (ein) => {
  // Remove all non-digits
  const cleaned = ein.replace(/\D/g, '');
  // Format as XX-XXXXXXX
  if (cleaned.length >= 2) {
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 9)}`;
  }
  return cleaned;
};

const formatPhoneNumber = (phone) => {
  // Remove all non-digits
  const cleaned = phone.replace(/\D/g, '');
  // Format as (XXX) XXX-XXXX
  if (cleaned.length >= 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
  return cleaned;
};

const validateEIN = (ein) => {
  // EIN format: XX-XXXXXXX
  const regex = /^\d{2}-\d{7}$/;
  return regex.test(ein);
};

const BUSINESS_TYPES = [
  { value: "", label: "Select a type" },
  { value: "sole_proprietorship", label: "Sole Proprietorship" },
  { value: "partnership", label: "Partnership" },
  { value: "llc", label: "Limited Liability Company (LLC)" },
  { value: "c_corp", label: "C Corporation" },
  { value: "s_corp", label: "S Corporation" },
  { value: "b_corp", label: "B Corporation" },
  { value: "non_profit", label: "Non-Profit" },
  { value: "cooperative", label: "Cooperative" }
];

const US_STATES = [
  { value: "", label: "Select a state" },
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];

const REFERRAL_SOURCES = [
  { value: "", label: "Select an option" },
  { value: "search", label: "Search Engine (Google, Bing, etc.)" },
  { value: "social", label: "Social Media" },
  { value: "friend", label: "Friend or Colleague" },
  { value: "advertisement", label: "Advertisement" },
  { value: "blog", label: "Blog or Article" },
  { value: "event", label: "Event or Conference" },
  { value: "partner", label: "Partner Referral" },
  { value: "other", label: "Other" }
];

function EmployerSignup() {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [error, setError] = useState(null);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [formData, setFormData] = useState({
    businessName: "",
    dba: "",
    ein: "",
    businessType: "",
    employeeCount: "",
    businessAddress: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
    businessPhone: "",
    referralSource: ""
  });

  const { open } = useFinchConnect({
    clientId: '96af0e91-d67a-451b-81a0-1fa7294a1448',
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    sandbox: true,
    onSuccess: ({ code }) => {
      console.log('Success! Authorization code:', code);
    },
    onError: ({ errorMessage }) => {
      console.error('Finch Connect error:', errorMessage);
    },
    onClose: () => {
      console.log('User closed Finch Connect');
    },
  });

  useEffect(() => {
    const checkExistingProfile = async () => {
      if (!user) {
        console.log('No user found, redirecting to login');
        navigate('/login');
        return;
      }

      try {
        console.log('Checking for existing business profile');
        const { data, error } = await supabaseService.getBusinessProfile(user.id);
        
        if (error) throw error;

        if (data) {
          console.log('Existing profile found, redirecting to dashboard');
          navigate('/employer/dashboard');
        } else {
          console.log('No existing profile, showing signup form');
          setShowSignupForm(true);
        }
      } catch (err) {
        console.error('Error checking profile:', err);
        setError(err.message);
      }
    };

    checkExistingProfile();
  }, [user, navigate]);

  if (!showSignupForm) {
    return <div className="employer-signup-loading">Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Format specific fields
    let formattedValue = value;
    if (name === 'ein') {
      formattedValue = formatEIN(value);
    } else if (name === 'businessPhone') {
      formattedValue = formatPhoneNumber(value);
    }
    
    setFormData(prev => ({ ...prev, [name]: formattedValue }));
  };

  const handleStepClick = (stepNumber) => {
    if (completedSteps.includes(stepNumber) || stepNumber === currentStep) {
      setCurrentStep(stepNumber);
    }
  };

  const handleSubmit = async () => {
    try {
      // Required fields validation
      const requiredFields = {
        businessName: 'Business Name',
        ein: 'EIN',
        businessType: 'Business Type',
        employeeCount: 'Number of Employees',
        businessAddress: 'Business Address',
        city: 'City',
        state: 'State',
        zip: 'ZIP Code',
        businessPhone: 'Business Phone'
      };

      const emptyFields = Object.entries(requiredFields)
        .filter(([field]) => !formData[field])
        .map(([, label]) => label);

      if (emptyFields.length > 0) {
        setError(`Please fill in the following required fields: ${emptyFields.join(', ')}`);
        return;
      }

      // EIN validation
      if (!validateEIN(formData.ein)) {
        setError('Please enter a valid EIN in the format XX-XXXXXXX');
        return;
      }

      console.log('Submitting validated data:', formData);
      setError(null);
      
      // Create the business profile with user_id
      await supabaseService.createBusinessProfile({
        ...formData,
        user_id: user.id
      });
      
      // Change this line - navigate to the main employer dashboard
      navigate('/employer');  // NOT /employer/dashboard
    } catch (error) {
      console.error('Form submission error:', error);
      setError(error.message || 'Failed to create business profile. Please try again.');
    }
  };

  const handleNextStep = () => {
    console.log('Next step clicked. Current step:', currentStep);
    
    if (currentStep === 3) {
      console.log('On final step - triggering submit');
      handleSubmit();
    } else {
      console.log('Advancing to next step');
      if (!completedSteps.includes(currentStep)) {
        setCompletedSteps([...completedSteps, currentStep]);
      }
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const renderFormStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="employer-signup-section-title">Business Information</div>
            <div className="employer-signup-section-description">Please provide your business details</div>
            
            <div className="employer-signup-form-group">
              <div className="employer-signup-input-row">
                <div className="employer-signup-input-field half-width">
                  <FormInput
                    name="businessName"
                    text="Business name"
                    value={formData.businessName}
                    onChange={handleInputChange}
                  />
                  <div className="employer-signup-input-helper">This is the full name you registered with the IRS.</div>
                </div>
                <div className="employer-signup-input-field half-width">
                  <FormInput
                    name="dba"
                    text="DBA"
                    value={formData.dba}
                    onChange={handleInputChange}
                  />
                  <div className="employer-signup-input-helper">Optional, enter if you use a different name for the business.</div>
                </div>
              </div>

              <div className="employer-signup-input-row">
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="ein"
                    text="EIN"
                    value={formData.ein}
                    onChange={handleInputChange}
                  />
                  <div className="employer-signup-input-helper">The government issued ID number you received when creating your business.</div>
                </div>
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="businessType"
                    text="Business type"
                    value={formData.businessType}
                    onChange={handleInputChange}
                    type="select"  // This makes it a dropdown
                    options={BUSINESS_TYPES}  // Using our predefined options
                  />
                </div>
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="employeeCount"
                    text="Number of employees"
                    value={formData.employeeCount}
                    onChange={handleInputChange}
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="employer-signup-form-group">
              <div className="employer-signup-form-group-header">Address</div>
              <div className="employer-signup-input-row">
                <div className="employer-signup-input-field three-quarters-width">
                  <FormInput
                    name="businessAddress"
                    text="Business address"
                    value={formData.businessAddress}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="employer-signup-input-field quarter-width">
                  <FormInput
                    name="suite"
                    text="Apt no., unit, suite"
                    value={formData.suite}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              
              <div className="employer-signup-input-row">
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="city"
                    text="City"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="state"
                    text="State"
                    value={formData.state}
                    onChange={handleInputChange}
                    type="select"  // This makes it a dropdown
                    options={US_STATES}  // Using our predefined options
                  />
                </div>
                <div className="employer-signup-input-field third-width">
                  <FormInput
                    name="zip"
                    text="Zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="employer-signup-input-field">
                <FormInput
                  name="businessPhone"
                  text="Business phone number"
                  value={formData.businessPhone}
                  onChange={handleInputChange}
                />
              </div>

              <div className="employer-signup-input-field">
                <FormInput
                  name="referralSource"
                  text="Where did you hear about Svalbard?"
                  value={formData.referralSource}
                  onChange={handleInputChange}
                  type="select"  // This makes it a dropdown
                  options={REFERRAL_SOURCES}  // Using our predefined options
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="employer-signup-section-title">Connect and sync your employee directory</div>
            <div className="employer-signup-section-description">
              By connecting your payroll, you can easily invite employees to Svalbard and manage payroll deductions for any out-of-pocket costs. Svalbard uses Finch to securely access your employee data.
            </div>
            
            <div className="connect-payroll-main">

              </div>
                <div className="connect-payroll-detail-card">
                  <button 
                    onClick={() => open()}
                    className="connect-bank-button"
                  >
                    Connect Payroll Provider
                  </button>
                </div>
                <div className="connect-payroll-detail-description">
                  To connect your payroll, please provide your payroll provider's details.
                </div>

            <div className="employer-signup-skip-section">
              If you'd like to skip this step for now, you can do so by clicking the button below. You can always connect your payroll later in your dashboard.
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="employer-signup-section-title">Connect your business bank account</div>
            <div className="employer-signup-section-description">
              Svalbard will use this bank account to cover your employees' plan premiums, any eligible medical expense costs, and Svalbard platform fees. If there are employee out-of-pocket costs, you will receive a payroll file monthly so you can deduct those costs from their payroll pre-tax.
            </div>

            <div className="employer-signup-section-description">
              You will be charged on the 15th of every month for healthcare costs and on the last day of the month for Svalbard platform fees. You will only be charged once your employees start using Svalbard.
            </div>

            <button className="connect-bank-button">
              Connect your bank account
            </button>

            <div className="plaid-security-note">
              <span className="lock-icon">🔒</span> Svalbard uses Plaid to securely connect your bank account
            </div>

            <div className="employer-signup-legal-text">
              By connecting your bank account, you authorize Svalbard Health Inc to debit the bank account specified above for any amount owed for charges arising from your use of Svalbard's services and/or purchase of products from Svalbard, pursuant to Svalbard's <a href="#" className="svalbard-link">employer platform agreement</a>, until this authorization is revoked. You may amend or cancel this authorization at any time by reaching out to support@getsvalbard.com.
            </div>

            <div className="employer-signup-legal-text">
              If you use Svalbard's services or purchase additional products periodically pursuant to our terms of use, you authorize Svalbard to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="employer-signup-container">
      <div className="employer-signup-sidebar">
        <div className="employer-signup-logo-section">
          <div className="employer-signup-logo-wrapper">
            <div className="employer-signup-logo-inner">
              <div className="employer-signup-logo-icon">
                <svg 
                  width="100%" 
                  height="100%" 
                  viewBox="0 0 72 72" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.7941 53.1078L9.70167 49.0548C1.70189 51.1739 0 58.6965 0 65.6046H4.25471C4.25471 57.0248 7.65849 54.5173 11.7941 53.1078Z" fill="currentColor"/>
                  <path d="M11.7941 53.1078C16.5889 60.7079 25.1765 65.6053 34.8824 65.6047C38.2851 65.6044 41.6878 64.9947 44.7811 63.7756C61.021 58.4416 72.0014 42.1377 72 22.6349C71.9997 17.9565 70.5453 12.953 68.8253 8.44065C60.6092 35.5907 44.2713 46.7064 11.7941 53.1078Z" fill="currentColor"/>
                  <path d="M64.5517 7C59.9365 11.0477 51.649 13.342 46.1694 13.3424C44.4681 13.3425 42.7667 13.1903 41.0653 12.7333C39.0545 12.2764 36.8891 11.9718 34.8784 11.9719C19.7208 11.973 7.65739 24.0107 7.65849 38.9426C7.69787 43.5024 8.32254 45.8666 9.70167 49.0548C39.402 42.7235 56.4122 31.8769 64.5517 7Z" fill="currentColor"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        
        <div className="employer-signup-nav-menu">
          <NavItem 
            text="Company Details" 
            stepNumber={1}
            isActive={currentStep === 1}
            isCompleted={completedSteps.includes(1)}
            onClick={() => handleStepClick(1)}
          />
          <NavItem 
            text="Connect your Payroll" 
            stepNumber={2}
            isActive={currentStep === 2}
            isCompleted={completedSteps.includes(2)}
            onClick={() => handleStepClick(2)}
          />
          <NavItem 
            text="Connect your Bank" 
            stepNumber={3}
            isActive={currentStep === 3}
            isCompleted={completedSteps.includes(3)}
            onClick={() => handleStepClick(3)}
          />
        </div>
        
        <div className="employer-signup-signout-section">
          <div 
            className="employer-signup-signout-wrapper"
            onClick={() => signOut()}
            style={{ cursor: 'pointer' }}
          >
            <div className="employer-signup-signout-icon">
              <svg 
                width="100%" 
                height="100%" 
                viewBox="0 0 12 12" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M4.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H4.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M8 8.5L10.5 6L8 3.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M10.5 6H4.5" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="employer-signup-signout-text">Sign out</div>
          </div>
        </div>
      </div>

      <div className="employer-signup-scrollable-content">
        <div className="employer-signup-main-content">
          <div className="employer-signup-form-section">
            {renderFormStep()}
          </div>
          <div className="employer-signup-button-container">
            {error && <div className="employer-signup-error">{error}</div>}
            <button className="employer-signup-submit-button" onClick={handleNextStep}>
              {currentStep === 3 ? 'Submit' : 'Save & Continue'}
            </button>
          </div>
        </div>
        <div className="employer-signup-preview-section">
          <div className="employer-signup-preview-container">
            <img 
              src={require('../assets/images/signup-preview-img.webp')} 
              alt="Customer testimonial" 
              className="employer-signup-preview-image"
            />
            <div className="employer-signup-preview-quote">
              My wealth has come from a combination of living in America, some lucky genes, and compound interest.
            </div>
            <div className="employer-signup-preview-author">
              <div className="employer-signup-preview-name">Warren Buffett</div>
              <div className="employer-signup-preview-title">CEO</div>
              <div className="employer-signup-preview-company">Berkshire Hathaway</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavItem({ text, stepNumber, isActive, isCompleted, onClick }) {
  return (
    <div 
      className={`employer-signup-nav-item ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}
      onClick={onClick}
      style={{ cursor: isCompleted || isActive ? 'pointer' : 'default' }}
    >
      <div className="employer-signup-nav-item-inner">
        <div className="employer-signup-nav-icon">
          <div className="employer-signup-nav-icon-box">
            <svg className="checked-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_403_717)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.49752 0.0867896L5.36027 0.0965396C4.02378 0.201507 2.76859 0.779921 1.8205 1.72774C0.872407 2.67556 0.293628 3.93057 0.188273 5.26704C0.175523 5.40504 0.175523 6.01479 0.188273 6.15279C0.24426 6.87304 0.439629 7.57555 0.763524 8.22129C1.03333 8.76598 1.39084 9.2626 1.82177 9.69129C2.44075 10.3111 3.19575 10.7779 4.02677 11.0548C4.45903 11.1972 4.90666 11.2878 5.36027 11.3248C5.49902 11.3368 6.10877 11.3368 6.24677 11.3248C6.96707 11.2686 7.66959 11.0729 8.31527 10.7488C8.9936 10.4116 9.5967 9.94064 10.0883 9.36429C10.5591 8.81205 10.9193 8.17449 11.1495 7.48629C11.292 7.05404 11.3826 6.60641 11.4195 6.15279C11.4315 6.01479 11.4315 5.40504 11.4195 5.26704C11.3635 4.54679 11.1682 3.84428 10.8443 3.19854C10.5067 2.52043 10.0356 1.91759 9.45902 1.42629C8.85153 0.908708 8.14152 0.525334 7.37552 0.30129C7.02277 0.199647 6.66114 0.131841 6.29552 0.0987896C6.02972 0.0863769 5.76358 0.082625 5.49752 0.0875396V0.0867896ZM8.71727 3.26829C8.78252 3.29079 8.81627 3.31104 8.86127 3.35604C8.89612 3.38838 8.9232 3.42819 8.94047 3.47248C8.95775 3.51677 8.96477 3.5644 8.96102 3.61179C8.96135 3.67675 8.942 3.74029 8.90552 3.79404C8.87552 3.83904 4.98827 7.72629 4.94252 7.75554C4.88622 7.79599 4.81738 7.81513 4.74827 7.80954C4.67917 7.81513 4.61033 7.79599 4.55402 7.75554C3.92874 7.14638 3.31143 6.52908 2.70227 5.90379C2.6659 5.85027 2.64656 5.787 2.64677 5.72229C2.64302 5.6749 2.65005 5.62727 2.66732 5.58298C2.6846 5.53868 2.71168 5.49888 2.74652 5.46654C2.77878 5.43179 2.81847 5.40478 2.86262 5.38751C2.90677 5.37024 2.95425 5.36315 3.00152 5.36679C3.07652 5.36979 3.12602 5.38479 3.18002 5.42079C3.19652 5.43204 3.55652 5.78754 3.97952 6.21054L4.74752 6.97929L6.57152 5.15679C7.18414 4.54291 7.79814 3.9304 8.41352 3.31929C8.45716 3.28901 8.50695 3.26873 8.55932 3.25992C8.6117 3.2511 8.66538 3.25396 8.71652 3.26829H8.71727Z" fill="currentColor"/>
              </g>
            </svg>
            <svg className="unchecked-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_373_626)">
                <path d="M6 10.875C8.69239 10.875 10.875 8.69239 10.875 6C10.875 3.30761 8.69239 1.125 6 1.125C3.30761 1.125 1.125 3.30761 1.125 6C1.125 8.69239 3.30761 10.875 6 10.875Z" stroke="currentColor" strokeWidth="0.75"/>
              </g>
            </svg>
          </div>
        </div>
        <div className="employer-signup-nav-text">{text}</div>
      </div>
    </div>
  );
}

function FormInput({ text, name, value, onChange, type = "text", options = [] }) {
  const [isFocused, setIsFocused] = useState(false);

  if (type === "select") {
    return (
      <div className="employer-signup-input-field">
        <div className={`employer-signup-input-wrapper ${isFocused ? 'focused' : ''}`}>
          <div className="employer-signup-input-container">
            <select
              className="employer-signup-input"
              name={name}
              value={value}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={(e) => {
                if (!e.target.value) {
                  setIsFocused(false);
                }
              }}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <label className="employer-signup-input-label" htmlFor={name}>
              {text}
            </label>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="employer-signup-input-field">
      <div className={`employer-signup-input-wrapper ${isFocused ? 'focused' : ''}`}>
        <div className="employer-signup-input-container">
          <input
            className={`employer-signup-input ${type === 'number' ? 'employer-signup-input-number' : ''}`}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder=" "
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              if (!e.target.value) {
                setIsFocused(false);
              }
            }}
          />
          <label className="employer-signup-input-label" htmlFor={name}>
            {text}
          </label>
        </div>
      </div>
    </div>
  );
}

export default EmployerSignup;
