import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SidebarExpansionIcon from '../assets/icons/sidebar-expansion-icon.svg';
import HomeIcon from '../assets/icons/HomeIcon';
import UserIcon from '../assets/icons/UserIcon';
import WalletIcon from '../assets/icons/WalletIcon';
import PayIcon from '../assets/icons/PayIcon';
import TransferIcon from '../assets/icons/TransferIcon';
import EntitiesIcon from '../assets/icons/EntitiesIcon';
import HelpIcon from '../assets/icons/HelpIcon';
import DocsIcon from '../assets/icons/DocsIcon.jsx';
import DevIcon from '../assets/icons/dev-icon.svg';
import RewardIcon from '../assets/icons/RewardIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';
import ExitIcon from '../assets/icons/ExitIcon';
import './indiv-sidebar.css';

function IndividualSidebar({ isSidebarExpanded, setIsSidebarExpanded }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [isUserAnimating, setIsUserAnimating] = useState(false);
  const [isWalletAnimating, setIsWalletAnimating] = useState(false);
  const [isPayAnimating, setIsPayAnimating] = useState(false);
  const [isHelpAnimating, setIsHelpAnimating] = useState(false);
  const [isHomeAnimating, setIsHomeAnimating] = useState(false);
  const [isTransferAnimating, setIsTransferAnimating] = useState(false);
  const [isEntitiesAnimating, setIsEntitiesAnimating] = useState(false);
  const [isDocsAnimating, setIsDocsAnimating] = useState(false);
  const [isRewardAnimating, setIsRewardAnimating] = useState(false);
  const [isSettingsAnimating, setIsSettingsAnimating] = useState(false);
  const [isExitAnimating, setIsExitAnimating] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNavItemClick = () => {
    if (!isSidebarExpanded) {
      setIsSidebarExpanded(true);
    }
  };

  return (
    <div className={`individual-sidebar ${isSidebarExpanded ? 'expanded' : ''}`}>
      <div className="individual-logo-section">
        <div className="individual-logo-wrapper">
          <div className="individual-logo-inner">
            <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7941 53.1078L9.70167 49.0548C1.70189 51.1739 0 58.6965 0 65.6046H4.25471C4.25471 57.0248 7.65849 54.5173 11.7941 53.1078Z" fill="currentColor"/>
              <path d="M11.7941 53.1078C16.5889 60.7079 25.1765 65.6053 34.8824 65.6047C38.2851 65.6044 41.6878 64.9947 44.7811 63.7756C61.021 58.4416 72.0014 42.1377 72 22.6349C71.9997 17.9565 70.5453 12.953 68.8253 8.44065C60.6092 35.5907 44.2713 46.7064 11.7941 53.1078Z" fill="currentColor"/>
              <path d="M64.5517 7C59.9365 11.0477 51.649 13.342 46.1694 13.3424C44.4681 13.3425 42.7667 13.1903 41.0653 12.7333C39.0545 12.2764 36.8891 11.9718 34.8784 11.9719C19.7208 11.973 7.65739 24.0107 7.65849 38.9426C7.69787 43.5024 8.32254 45.8666 9.70167 49.0548C39.402 42.7235 56.4122 31.8769 64.5517 7Z" fill="currentColor"/>
            </svg>
          </div>
        </div>
        {isSidebarExpanded && (
          <button 
            className="sidebar-toggle"
            onClick={() => setIsSidebarExpanded(false)}
          >
            <img 
              src={SidebarExpansionIcon} 
              alt="Toggle sidebar"
              style={{ width: '16px', height: '16px' }}
            />
          </button>
        )}
      </div>

      <div className="individual-nav-menu">
        <div className="individual-primary-nav">
          <Link 
            to="/individual/dashboard"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsHomeAnimating(false);
              setTimeout(() => setIsHomeAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <HomeIcon isAnimating={isHomeAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Home
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/wallet"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/wallet' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsWalletAnimating(false);
              setTimeout(() => setIsWalletAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <WalletIcon isAnimating={isWalletAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Wallet
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/transfer"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/transfer' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsTransferAnimating(false);
              setTimeout(() => setIsTransferAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <TransferIcon isAnimating={isTransferAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Transfer
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/pay"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/pay' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsPayAnimating(false);
              setTimeout(() => setIsPayAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <PayIcon isAnimating={isPayAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Pay
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/entities"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/entities' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsEntitiesAnimating(false);
              setTimeout(() => setIsEntitiesAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <EntitiesIcon isAnimating={isEntitiesAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Entities
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/help"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/help' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsHelpAnimating(false);
              setTimeout(() => setIsHelpAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <HelpIcon isAnimating={isHelpAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Help
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/docs"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/docs' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsDocsAnimating(false);
              setTimeout(() => setIsDocsAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <DocsIcon isAnimating={isDocsAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Documentation
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/dev"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/dev' ? 'active' : ''}`}
            onClick={handleNavItemClick}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <img 
                  src={DevIcon} 
                  alt="Dev" 
                  className="individual-nav-icon-circle"
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Developer
              </div>
            )}
          </Link>
        </div>
        
        <div className="individual-secondary-nav">
          <Link 
            to="/individual/dashboard/profile"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/profile' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsUserAnimating(false);
              setTimeout(() => setIsUserAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <UserIcon isAnimating={isUserAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Profile
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/rewards"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/rewards' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsRewardAnimating(false);
              setTimeout(() => setIsRewardAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <RewardIcon isAnimating={isRewardAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Rewards
              </div>
            )}
          </Link>

          <Link 
            to="/individual/dashboard/settings"
            className={`individual-nav-item ${location.pathname === '/individual/dashboard/settings' ? 'active' : ''}`}
            onClick={() => {
              handleNavItemClick();
              setIsSettingsAnimating(false);
              setTimeout(() => setIsSettingsAnimating(true), 0);
            }}
          >
            <div className="nav-icon-container">
              <div className="individual-nav-icon">
                <SettingsIcon isAnimating={isSettingsAnimating} />
              </div>
            </div>
            {isSidebarExpanded && (
              <div className="individual-nav-label" key={`label-${isSidebarExpanded}`}>
                Settings
              </div>
            )}
          </Link>
        </div>
      </div>

      <div className="individual-signout-section">
        <div 
          className="individual-signout-wrapper"
          onClick={handleSignOut}
          onMouseEnter={() => setIsExitAnimating(true)}
          onMouseLeave={() => setIsExitAnimating(false)}
          style={{ cursor: 'pointer' }}
        >
          <div className="individual-signout-content">
            <div className="individual-signout-icon">
              <ExitIcon isAnimating={isExitAnimating} />
            </div>
            {isSidebarExpanded && (
              <div className="individual-signout-text" key={`signout-${isSidebarExpanded}`}>
                Sign out
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualSidebar;
