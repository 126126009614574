import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import IndividualSidebar from '../layouts/indiv-sidebar';
import ClosedEyeIcon from '../assets/icons/closed-eye-icon.svg';
import RightArrowIcon from '../assets/icons/right-arrow-icon.svg';
import DropDownArrowIcon from '../assets/icons/dropdown-arrow-icon.svg';
import UnderMaintenance from '../static-components/under-maint';
import './indiv-dashboard.css';
import BalanceGraph from '../dashboard-components/BalanceGraph';
import supabaseService from '../services/supabase-service';

const getUserProfile = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

const updateUserBalance = async (userId, newBalance) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .update({ currentBalance: newBalance })
      .eq('id', userId);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating user balance:', error);
    throw error;
  }
};

function DashboardContent() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [accountData, setAccountData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        if (user?.sub) {
          const userData = await getUserProfile(user.sub);
          console.log('Fetched user data:', userData);
          setAccountData(userData);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [user]);

  const balance = accountData?.currentBalance || 0;
  const formattedBalance = balance.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const mockData = [
    { date: new Date('2024-01-01'), value: 1000 },
    { date: new Date('2024-02-01'), value: 1200 },
    { date: new Date('2024-03-01'), value: 1150 },
    { date: new Date('2024-04-01'), value: 1400 },
    { date: new Date('2024-05-01'), value: 1700 },
  ];

  return (
    <div className="indiv-dashboard-content">
      <div className="indiv-dashboard-header-new">
        <div className="indiv-dashboard-welcome-text-new">
          Welcome, {accountData?.firstName || 'User'}
        </div>
      </div>
      <div className="indiv-dashboard-content-area-new">
        {/* Balance Card */}
        <div className="indiv-dashboard-card-new">
          <div className="indiv-dashboard-balance-section">
            <div className="indiv-dashboard-balance-header">Balance</div>
            <div className="indiv-dashboard-balance-content">
              <BalanceGraph data={mockData} width={400} height={200} />
              <div className="indiv-dashboard-balance-amount-container">
                <div className="indiv-dashboard-balance-number">${formattedBalance}</div>
                <div className="indiv-dashboard-eye-icon">
                  <img src={ClosedEyeIcon} alt="Hide Balance" />
                </div>
              </div>
              <div className="indiv-dashboard-profit">+$1,700.67 (+7.00%) all time</div>
            </div>
          </div>
        </div>

        {/* Account Management Card */}
        <div className="indiv-dashboard-card-new">
          <div className="indiv-dashboard-card-content">
            <div className="indiv-dashboard-card-title-new">Account management</div>
            <div className="indiv-dashboard-grid">
              <div className="indiv-dashboard-grid-row">
                <div className="indiv-dashboard-grid-item" onClick={() => navigate('/submit-expense')}>
                  <div className="indiv-dashboard-grid-icon"></div>
                  <div className="indiv-dashboard-grid-content">
                    <div className="indiv-dashboard-grid-text">
                      <div className="indiv-dashboard-grid-title">Submit an expense</div>
                      <div className="indiv-dashboard-grid-subtitle">Get reimbursed for healthcare spending</div>
                    </div>
                    <div className="indiv-dashboard-grid-arrow">
                      <img src={RightArrowIcon} alt="Arrow" />
                    </div>
                  </div>
                </div>
                <div className="indiv-dashboard-grid-item" onClick={() => navigate('/manage-investments')}>
                  <div className="indiv-dashboard-grid-icon"></div>
                  <div className="indiv-dashboard-grid-content">
                    <div className="indiv-dashboard-grid-text">
                      <div className="indiv-dashboard-grid-title">Manage investments</div>
                      <div className="indiv-dashboard-grid-subtitle">Grow your health savings</div>
                    </div>
                    <div className="indiv-dashboard-grid-arrow">
                      <img src={RightArrowIcon} alt="Arrow" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="indiv-dashboard-grid-row">
                <div className="indiv-dashboard-grid-item" onClick={() => navigate('/recent-activity')}>
                  <div className="indiv-dashboard-grid-icon"></div>
                  <div className="indiv-dashboard-grid-content">
                    <div className="indiv-dashboard-grid-text">
                      <div className="indiv-dashboard-grid-title">Recent activity</div>
                      <div className="indiv-dashboard-grid-subtitle">View recent account activity</div>
                    </div>
                    <div className="indiv-dashboard-grid-arrow">
                      <img src={RightArrowIcon} alt="Arrow" />
                    </div>
                  </div>
                </div>
                <div className="indiv-dashboard-grid-item" onClick={() => navigate('/account-settings')}>
                  <div className="indiv-dashboard-grid-icon"></div>
                  <div className="indiv-dashboard-grid-content">
                    <div className="indiv-dashboard-grid-text">
                      <div className="indiv-dashboard-grid-title">Account settings</div>
                      <div className="indiv-dashboard-grid-subtitle">View account settings</div>
                    </div>
                    <div className="indiv-dashboard-grid-arrow">
                      <img src={RightArrowIcon} alt="Arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contributions Card */}
        <div className="indiv-dashboard-card-new">
          <div className="indiv-dashboard-card-title-new">Contributions overview</div>
          <div className="indiv-dashboard-contributions">
            <div className="indiv-dashboard-contributions-header">
              <span>2024 contributions</span>
              <span>94%</span>
            </div>
            <div className="indiv-dashboard-progress-bar">
              <div className="indiv-dashboard-progress-fill">
                <span>80%</span>
              </div>
              <div className="indiv-dashboard-progress-remainder"></div>
            </div>
            <div className="indiv-dashboard-contributions-header">
              <span>Annual limit:</span>
              <span>$4137.50/$4400.00</span>
            </div>
          </div>
          <div className="indiv-dashboard-footer" onClick={() => navigate('/individual/manage-contributions')}>
            <span className="indiv-dashboard-footer-text">Manage contributions</span>
            <div className="indiv-dashboard-card-arrow">
              <img src={RightArrowIcon} alt="Arrow" />
            </div>
          </div>
        </div>

        {/* Notifications Card */}
        <div className="indiv-dashboard-card-new">
          <div className="indiv-dashboard-notifications-container">
            <div className="indiv-dashboard-card-title-new">Notifications</div>
            <div className="indiv-dashboard-notifications">
              {[1, 2, 3].map((_, index) => (
                <div key={index} className="indiv-dashboard-notification-item">
                  <div className="indiv-dashboard-grid-icon"></div>
                  <div className="indiv-dashboard-grid-text">
                    <div className="indiv-dashboard-grid-title">Get a head start on your taxes this year</div>
                    <div className="indiv-dashboard-grid-subtitle">(to maintain required reserve amount)</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="indiv-dashboard-footer" onClick={() => navigate('/notifications')}>
            <span className="indiv-dashboard-footer-text">View all</span>
            <div className="indiv-dashboard-card-arrow">
              <img src={RightArrowIcon} alt="Arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ConnectEmployerContent component remains the same
function ConnectEmployerContent() {
  return (
    <div className="connect-employer-main">
      <div className="connect-employer-main-header">
        <div className="connect-employer-title">Connect your employer</div>
      </div>
      <div className="connect-employer-details">
        <div className="connect-employer-detail-card">
          <div className="connect-employer-detail-title">Your current employment details</div>
          <div className="connect-employer-detail-item">
            <div className="connect-employer-detail-label">Name of employer:</div>
            <div className="connect-employer-detail-value">Apple Inc.</div>
          </div>
          <div className="connect-employer-detail-item">
            <div className="connect-employer-detail-label">Work email</div>
            <div className="connect-employer-detail-value">john@apple.com</div>
          </div>
          <div className="connect-employer-detail-description">
            In order to connect your employer to Svalbard, you must help us get in contact with your employer's HR.
          </div>
        </div>
      </div>
    </div>
  );
}

function ManageContributionsContent() {
  console.log('ManageContributionsContent rendered');
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [contributionAmount, setContributionAmount] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAccountData() {
      try {
        if (user?.id) {
          const { data, error } = await supabase
            .from('indiv_profiles')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (error) throw error;
          setAccountData(data);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      }
    }

    fetchAccountData();
  }, [user]);

  const ANNUAL_LIMIT = 4150;
  const currentBalance = accountData?.currentBalance || 0;
  const progressPercentage = Math.min((currentBalance / ANNUAL_LIMIT) * 100, 100);
  
  const formattedCurrentBalance = currentBalance.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedAnnualLimit = ANNUAL_LIMIT.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const bankAccounts = [
    { id: 1, name: 'Chase Checking', lastFour: '4321' },
    { id: 2, name: 'Bank of America Savings', lastFour: '7890' },
    { id: 3, name: 'Wells Fargo Checking', lastFour: '2468' }
  ];

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value.split('.').length > 2) return;
    setContributionAmount(value);
  };

  const handleContribution = async () => {
    try {
      if (!contributionAmount || isNaN(contributionAmount)) {
        alert('Please enter a valid amount');
        return;
      }

      const amount = parseFloat(contributionAmount);
      if (amount <= 0) {
        alert('Please enter an amount greater than 0');
        return;
      }

      const { error } = await supabase
        .from('indiv_profiles')
        .update({ 
          currentBalance: (currentBalance + amount),
          updated_at: new Date().toISOString()
        })
        .eq('user_id', user.id);

      if (error) throw error;

      alert('Contribution successful!');
      navigate('/individual/dashboard');
    } catch (error) {
      console.error('Error processing contribution:', error);
      alert('Failed to process contribution. Please try again.');
    }
  };

  return (
    <div className="indiv-dashboard-content">
      <div className="indiv-dashboard-header">
        <div className="indiv-dashboard-welcome-text">Manage Contributions</div>
      </div>
      <div className="indiv-dashboard-content-area">
        <div className="indiv-dashboard-right-column">
          <div className="indiv-dashboard-card">
            <div className="contribution-progress">
              <div className="progress-percentage">{progressPercentage.toFixed(0)}%</div>
              <div className="indiv-dashboard-progress-bar"></div>
              <div className="progress-limit">
                <span>Annual limit:</span>
                <span>{formattedCurrentBalance}/{formattedAnnualLimit}</span>
              </div>
            </div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Make a contribution</div>
            </div>
            <div className="contribution-form">
              <div className="contribution-input">
                <div 
                  className="dropdown-trigger"
                  onClick={() => setIsFromDropdownOpen(!isFromDropdownOpen)}
                >
                  <div className="input-label">
                    {selectedAccount 
                      ? `From: ${selectedAccount.name} (...${selectedAccount.lastFour})`
                      : 'From:'
                    }
                  </div>
                  <img 
                    src={DropDownArrowIcon} 
                    alt="Dropdown Arrow" 
                    className={`dropdown-arrow ${isFromDropdownOpen ? 'open' : ''}`}
                  />
                </div>
                {isFromDropdownOpen && (
                  <div className="dropdown-content">
                    {bankAccounts.map(account => (
                      <button 
                        key={account.id}
                        className="link-account-button"
                        onClick={() => {
                          setSelectedAccount(account);
                          setIsFromDropdownOpen(false);
                        }}
                      >
                        <span>{account.name} (...{account.lastFour})</span>
                      </button>
                    ))}
                    <button className="link-account-button">
                      <span>Link another account</span>
                      <span>+</span>
                    </button>
                  </div>
                )}
              </div>
              <div className="contribution-input">
                <div className="input-label">To: Your Personal HSA</div>
                <img src={DropDownArrowIcon} alt="Dropdown Arrow" className="dropdown-arrow" />
              </div>
              <div className="contribution-amount">
                <div className="amount-label">Amount:</div>
                <input
                  type="text"
                  value={contributionAmount}
                  onChange={handleAmountChange}
                  placeholder="0.00"
                  className="amount-value"
                  style={{
                    border: 'none',
                    background: 'transparent',
                    fontSize: '16px',
                    width: '100%',
                    outline: 'none',
                    fontFamily: 'TWK Lausanne 350'
                  }}
                />
              </div>
              <div className="contribution-actions">
                <button 
                  className="contribution-next-button"
                  onClick={handleContribution}
                >
                  Process contribution
                </button>
              </div>
            </div>
          </div>
          <div className="indiv-dashboard-card">
            <div className="indiv-dashboard-card-header">
              <div className="indiv-dashboard-card-title">Recent Activity</div>
              <div className="arrow-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function UserSettingsContent() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const US_STATES = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user?.id) {
          const { data, error } = await supabase
            .from('indiv_profiles')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (error) throw error;

          setFormData({
            firstName: data.first_name || '',
            middleName: data.middle_name || '',
            lastName: data.last_name || '',
            email: user.email || '',
            phoneNumber: data.phone_number || '',
            streetAddress: data.street_address || '',
            city: data.city || '',
            state: data.state || '',
            zipCode: data.zip_code || '',
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        setMessage({ 
          text: `Error loading user data: ${error.message}`, 
          type: 'error' 
        });
      }
    };

    fetchUserData();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error } = await supabase
        .from('indiv_profiles')
        .update({
          first_name: formData.firstName,
          middle_name: formData.middleName,
          last_name: formData.lastName,
          phone_number: formData.phoneNumber,
          street_address: formData.streetAddress,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          updated_at: new Date(),
        })
        .eq('user_id', user.id);

      if (error) throw error;

      // Update email if changed
      if (formData.email !== user.email) {
        const { error: emailError } = await supabase.auth.updateUser({
          email: formData.email,
        });
        if (emailError) throw emailError;
      }

      setMessage({ text: 'Profile updated successfully', type: 'success' });
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ text: 'Error updating profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage({ text: 'New passwords do not match', type: 'error' });
      return;
    }

    setLoading(true);
    try {
      const { error } = await supabaseService.updateUserPassword(passwordData.newPassword);
      if (error) throw error;

      setMessage({ text: 'Password updated successfully', type: 'success' });
      setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
      setShowPasswordChange(false);
    } catch (error) {
      console.error('Error updating password:', error);
      setMessage({ text: 'Error updating password', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="indiv-dashboard-content">
      <div className="indiv-dashboard-header-new">
        <div className="indiv-dashboard-welcome-text-new">
            Profile information 
        </div>
      </div>
      
      <div className="indiv-dashboard-content-area-nogrid">
        {/* Personal Information Section */}
        <div className="settings-section">
          <div className="indiv-signup-form-group">
            <div className="indiv-signup-form-group-header">Personal Information</div>
            <form onSubmit={updateProfile}>
              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field third-width">
                  <label className="settings-label">
                    First Name<span className="settings-required">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="settings-input"
                  />
                </div>

                <div className="indiv-signup-input-field third-width">
                  <label className="settings-label">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    name="middleName"
                    value={formData.middleName}
                    onChange={handleInputChange}
                    className="settings-input"
                  />
                </div>

                <div className="indiv-signup-input-field third-width">
                  <label className="settings-label">
                    Last Name<span className="settings-required">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="settings-input"
                  />
                </div>
              </div>

              <div className="indiv-signup-input-row">
                <div className="indiv-signup-input-field half-width">
                  <label className="settings-label">
                    Email address<span className="settings-required">*</span>
                  </label>
                  <div className="settings-input-with-badge">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="settings-input"
                      disabled
                    />
                    <span className="settings-verified">Verified</span>
                  </div>
                </div>

                <div className="indiv-signup-input-field half-width">
                  <label className="settings-label">
                    Phone Number<span className="settings-required">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="settings-input"
                  />
                </div>
              </div>

              <div className="indiv-signup-form-group">
                <div className="indiv-signup-form-group-header">Address</div>
                <div className="indiv-signup-input-field">
                  <label className="settings-label">
                    Street Address<span className="settings-required">*</span>
                  </label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    className="settings-input"
                  />
                </div>

                <div className="indiv-signup-input-row">
                  <div className="indiv-signup-input-field third-width">
                    <label className="settings-label">
                      City<span className="settings-required">*</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      className="settings-input"
                    />
                  </div>

                  <div className="indiv-signup-input-field third-width">
                    <label className="settings-label">
                      State<span className="settings-required">*</span>
                    </label>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="settings-input"
                    >
                      <option value="">Select a state</option>
                      {US_STATES.map(state => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="indiv-signup-input-field third-width">
                    <label className="settings-label">
                      ZIP Code<span className="settings-required">*</span>
                    </label>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleInputChange}
                      className="settings-input"
                    />
                  </div>
                </div>
              </div>

              <div className="settings-actions">
                <button type="submit" className="settings-save-button" disabled={loading}>
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Password Section */}
        <div className="settings-section">
          <div className="indiv-signup-form-group">
            <div className="indiv-signup-form-group-header">Password</div>
            <form onSubmit={updatePassword}>
              <div className="indiv-signup-input-field">
                <label className="settings-label">New Password</label>
                <div className="settings-password-input">
                  <input
                    type="password"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    className="settings-input"
                    placeholder="New Password"
                  />
                  <button type="button" className="settings-password-toggle">
                    <span className="settings-password-eye">👁</span>
                  </button>
                </div>
              </div>

              <div className="indiv-signup-input-field">
                <label className="settings-label">Repeat Password</label>
                <div className="settings-password-input">
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    className="settings-input"
                    placeholder="Repeat Password"
                  />
                  <button type="button" className="settings-password-toggle">
                    <span className="settings-password-eye">👁</span>
                  </button>
                </div>
              </div>

              <div className="indiv-signup-input-field">
                <label className="settings-label">Current Password</label>
                <div className="settings-password-input">
                  <input
                    type="password"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                    className="settings-input"
                    placeholder="••••••••••"
                  />
                  <button type="button" className="settings-password-toggle">
                    <span className="settings-password-eye">👁</span>
                  </button>
                </div>
              </div>

              <div className="settings-actions">
                <button type="submit" className="settings-save-button" disabled={loading}>
                  Update password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function IndividualDashboard() {
  console.log('IndividualDashboard rendered');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  return (
    <div className="indiv-dashboard-container">
      <IndividualSidebar 
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      <Routes>
        <Route path="/dashboard" element={<DashboardContent />} />
        <Route path="/dashboard/settings" element={<UserSettingsContent />} />
        <Route path="/dashboard/connect-employer" element={<ConnectEmployerContent />} />
        <Route path="/dashboard/manage-contributions" element={<ManageContributionsContent />} />
        <Route path="/dashboard/profile" element={<UnderMaintenance pageName="Profile" />} />
        <Route path="/dashboard/wallet" element={<UnderMaintenance pageName="Wallet" />} />
        <Route path="/dashboard/transfer" element={<UnderMaintenance pageName="Transfer" />} />
        <Route path="/dashboard/pay" element={<UnderMaintenance pageName="Pay" />} />
        <Route path="/dashboard/entities" element={<UnderMaintenance pageName="Entities" />} />
        <Route path="/dashboard/help" element={<UnderMaintenance pageName="Help Center" />} />
        <Route path="/dashboard/docs" element={<UnderMaintenance pageName="Documentation" />} />
        <Route path="/dashboard/dev" element={<UnderMaintenance pageName="Developer" />} />
        <Route path="/dashboard/rewards" element={<UnderMaintenance pageName="Rewards" />} />
      </Routes>
    </div>
  );
}

export default IndividualDashboard;