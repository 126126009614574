import React from 'react';

const DocsIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Static document */}
      <path 
        d="M15 15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333C13.7754 2.5 14.1993 2.67559 14.5118 2.98816C14.8244 3.30072 15 3.72464 15 4.16667V15.8333Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />

      {/* Lines - ordered from top to bottom */}
      <path 
        className={`doc-line-1 ${isAnimating ? 'animate' : ''}`}
        d="M5.8335 6.66675H11.6702" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className={`doc-line-2 ${isAnimating ? 'animate' : ''}`}
        d="M5.8335 10H11.6702" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className={`doc-line-3 ${isAnimating ? 'animate' : ''}`}
        d="M5.8335 13.3333H11.6702" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className={`doc-line-4 ${isAnimating ? 'animate' : ''}`}
        d="M5.8335 13.3333H11.6702" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        opacity="0"
      />

      {/* Pen */}
      <g className={`pen ${isAnimating ? 'animate' : ''}`}>
        <path 
          d="M10.8335 11.1784V13.3334H12.9885C13.2095 13.3334 13.4214 13.2456 13.5777 13.0893L16.9827 9.68429C17.3141 9.35269 17.5002 8.90308 17.5002 8.43429C17.5002 7.96549 17.3141 7.51588 16.9827 7.18429C16.6511 6.8529 16.2015 6.66675 15.7327 6.66675C15.2639 6.66675 14.8143 6.8529 14.4827 7.18429L11.0777 10.5893C10.9214 10.7455 10.8336 10.9574 10.8335 11.1784Z" 
          stroke="currentColor" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          fill="#f4f3ef"
        />
      </g>
    </svg>
  );
};

export default DocsIcon; 