import React from 'react';

const ExitIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`exit-icon ${isAnimating ? 'animate' : ''}`}
    >
      <path 
        className="exit-door"
        d="M3 5.5366C3 4.63049 3.60917 3.8376 4.48467 3.60413L7.98467 2.67079C9.25437 2.33221 10.5 3.2892 10.5 4.60326V15.3968C10.5 16.7108 9.25437 17.6678 7.98467 17.3292L4.48468 16.3959C3.60917 16.1624 3 15.3695 3 14.4634V5.5366Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className="exit-doorknob"
        d="M7.5 11.25C8.19036 11.25 8.75 10.6904 8.75 10C8.75 9.30964 8.19036 8.75 7.5 8.75C6.80964 8.75 6.25 9.30964 6.25 10C6.25 10.6904 6.80964 11.25 7.5 11.25Z" 
        fill="currentColor"
      />
      <path 
        className="exit-arrow"
        d="M12.5 10H17.6667M17.6667 10L15.1667 7.5M17.6667 10L15.1667 12.5" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExitIcon; 