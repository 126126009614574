import React from 'react';

const TransferIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        className={`arrow-right ${isAnimating ? 'animate' : ''}`}
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M11.8864 10.5529C12.1793 10.26 12.6541 10.26 12.947 10.5529L15.947 13.5529C16.0877 13.6936 16.1667 13.8843 16.1667 14.0832C16.1667 14.2822 16.0877 14.4729 15.947 14.6136L12.947 17.6136C12.6541 17.9065 12.1793 17.9065 11.8864 17.6136C11.5935 17.3207 11.5935 16.8458 11.8864 16.5529L13.606 14.8332L3.75 14.8333C3.33578 14.8333 3 14.4975 3 14.0833C3 13.669 3.33578 13.3333 3.75 13.3333L13.606 13.3332L11.8864 11.6136C11.5935 11.3207 11.5935 10.8458 11.8864 10.5529Z" 
        fill="currentColor"
      />
      <path 
        className={`arrow-left ${isAnimating ? 'animate' : ''}`}
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M7.28033 2.21967C7.57322 2.51257 7.57322 2.98744 7.28033 3.28033L5.56066 5L15.4167 5.00001C15.8309 5.00001 16.1667 5.33579 16.1667 5.75001C16.1667 6.16422 15.8309 6.50001 15.4167 6.50001L5.56066 6.5L7.28033 8.21967C7.57322 8.51257 7.57322 8.98744 7.28033 9.28033C6.98743 9.57323 6.51256 9.57323 6.21967 9.28033L3.21967 6.28033C3.07901 6.13968 3 5.94892 3 5.75C3 5.55109 3.07901 5.36033 3.21967 5.21967L6.21967 2.21967C6.51256 1.92678 6.98743 1.92678 7.28033 2.21967Z" 
        fill="currentColor"
      />
    </svg>
  );
};

export default TransferIcon; 