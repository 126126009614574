import React from 'react';

const RewardIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={`reward-icon ${isAnimating ? 'animate' : ''}`}
    >
      <path 
        className="reward-circle"
        d="M9.99998 12C12.5773 12 14.6666 9.91062 14.6666 7.33329C14.6666 4.75596 12.5773 2.66663 9.99998 2.66663C7.42265 2.66663 5.33331 4.75596 5.33331 7.33329C5.33331 9.91062 7.42265 12 9.99998 12Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className="reward-ribbon"
        d="M7.47335 11.26L6.66669 17.3333L10 15.3333L13.3334 17.3333L12.5267 11.2533" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RewardIcon;
