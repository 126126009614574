import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import "./App.css";
import "./styles/fonts.css";
import "./styles/typography.css";
import './styles/animations.css';

// Import your components
import LandingPage from "./static-pages/landing-page";
import ResourcesPage from "./static-pages/resources-page";
import PricingPage from "./static-pages/pricing-page";
import ForBusinessPage from "./static-pages/for-business-page";
import ProductsPage from "./static-pages/products-page";
import IndividualHSAPage from "./static-pages/indiv-hsa-page";
import IndividualDashboard from "./dashboard-components/indiv-dashboard";
import EmployerDashboard from "./dashboard-components/employer-dashboard";
import EmployerSignup from './dashboard-components/employer-signup';
import LoginPage from './pages/LoginPage';
import IndivSignup from "./dashboard-components/indiv-signup";
import SignupSelector from './dashboard-components/SignupSelector';

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!user) {
    return <Navigate to="/signup" />;
  }
  
  return children;
}

function App() {
  console.log("App component rendering");

  return (
    <div className="App">
      <Routes>
        {/* Public routes */}
        <Route path="/" element={
          <React.Suspense fallback={<div>Loading...</div>}>
            <LandingPage />
          </React.Suspense>
        } />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/for-business" element={<ForBusinessPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/individual-hsa" element={<IndividualHSAPage />} />
        <Route path="/employer-signup" element={<EmployerSignup />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupSelector />} />
        <Route path="/signup/individual" element={<IndivSignup />} />
        <Route path="/signup/employer" element={<EmployerSignup />} />
        
        {/* Protected routes */}
        <Route 
          path="/individual/*" 
          element={
            <ProtectedRoute>
              <React.Suspense fallback={<div>Loading dashboard...</div>}>
                <IndividualDashboard />
              </React.Suspense>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/employer/dashboard/*" 
          element={
            <ProtectedRoute>
              <EmployerDashboard />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </div>
  );
}

export default App;