import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ReactComponent as Logo } from '../assets/images/svalbard-logo.svg';
import './navbar.css';
import NavDropdown from './NavDropdown';

const Navbar = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const lightBackgroundRoutes = ['/resources', '/pricing', '/products'];
  const isDarkMode = lightBackgroundRoutes.includes(location.pathname);

  const navLinks = [
    {
      text: "Individual HSA",
      path: "/individual-hsa",
      submenu: [
        {
          text: "HSA Basics",
          description: "Learn about Health Savings Accounts"
        },
        {
          text: "Benefits",
          description: "Discover the advantages of an HSA"
        }
      ]
    },
    {
      text: "For Business",
      path: "/for-business",
      submenu: [
        {
          text: "Employer Solutions",
          description: "HSA solutions for employers"
        },
        {
          text: "Integration",
          description: "Seamless integration with your systems"
        }
      ]
    },
    {
      text: "Products",
      path: "/products",
      submenu: {
        sections: [
          {
            title: "Products",
            items: [
              {
                text: "Health Savings Accounts",
                description: "Manage and grow your HSA"
              },
              {
                text: "Reimbursement Processing",
                description: "Fast and efficient reimbursements"
              },
              {
                text: "Debit Cards",
                description: "Smart HSA-linked cards"
              },
              {
                text: "Marketplace",
                description: "HSA-eligible products"
              }
            ]
          },
          {
            title: "Platform",
            items: [
              {
                text: "Integrations",
                description: "Employer-employee connections"
              },
              {
                text: "HSA Accounting",
                description: "Streamlined HSA bookkeeping"
              },
              {
                text: "HSA Reporting",
                description: "Comprehensive reporting tools"
              }
            ]
          }
        ]
      }
    },
    {
      text: "Pricing",
      path: "/pricing",
      submenu: [
        {
          text: "Individual Plans",
          description: "Flexible plans for personal use"
        },
        {
          text: "Business Plans",
          description: "Scalable solutions for companies"
        },
        {
          text: "Enterprise",
          description: "Custom pricing for large organizations"
        }
      ]
    },
    {
      text: "Resources",
      path: "/resources",
      submenu: [
        {
          text: "Help Center",
          description: "Find answers to your questions"
        },
        {
          text: "Blog",
          description: "Latest updates and insights"
        },
        {
          text: "Documentation",
          description: "Learn how to use Svalbard"
        }
      ]
    }
  ];

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignOut = async () => {
    if (isSigningOut) return; // Prevent multiple clicks
    
    try {
      setIsSigningOut(true);
      console.log('Navbar: Starting sign out');
      await signOut();
      console.log('Navbar: Sign out successful');
    } catch (error) {
      console.error('Navbar: Error during sign out:', error);
      alert('Failed to sign out. Please try again.');
    } finally {
      setIsSigningOut(false);
    }
  };

  const handleMouseEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar-container ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isScrolled ? 'scrolled' : ''}`}>
      <div className="navbar-content">
        <div className="navbar-logo-container" onClick={() => navigate("/")}>
          <Logo className="navbar-logo" />
        </div>

        <div className="navbar-links">
          {navLinks.map((link, index) => (
            <NavDropdown
              key={index}
              link={link}
              isOpen={activeDropdown === index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </div>

        <div className="navbar-auth">
          {!user ? (
            <div className="navbar-auth-buttons">
              <div className="navbar-signup" onClick={handleLogin}>
                <div className="navbar-signup-circle" />
                <div className="navbar-signup-text">Login / Sign Up</div>
              </div>
            </div>
          ) : (
            <div className="navbar-user-info">
              <div className="navbar-user-name">
                {user.email}
              </div>
              <button
                onClick={handleSignOut}
                className="navbar-logout"
                disabled={isSigningOut}
              >
                {isSigningOut ? 'Signing out...' : 'Log Out'}
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;