import React from 'react';

const PayIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        className={`coin-2 ${isAnimating ? 'animate' : ''}`}
        d="M9.5 7C10.8807 7 12 5.88071 12 4.5C12 3.11929 10.8807 2 9.5 2C8.11929 2 7 3.11929 7 4.5C7 5.88071 8.11929 7 9.5 7Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        fill="#f4f3ef"
      />
      <path 
        className={`coin-1 ${isAnimating ? 'animate' : ''}`}
        d="M12.5 8C13.8807 8 15 6.88071 15 5.5C15 4.11929 13.8807 3 12.5 3C11.1193 3 10 4.11929 10 5.5C10 6.88071 11.1193 8 12.5 8Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        fill="#f4f3ef"
      />
      <g className={`hand ${isAnimating ? 'animate' : ''}`}>
        <path 
          d="M6 16.4998L10.559 17.4116C10.8494 17.4697 11.149 17.4625 11.4363 17.3907L14.6404 16.5897C14.8781 16.5303 15.1018 16.4293 15.2959 16.2797C16.138 15.6311 18.428 13.6975 17.5 12.4998C16.3717 11.0437 14.8441 13.9942 13 14.1698C12.2225 14.2438 10.83 14.1698 10.83 14.1698" 
          stroke="currentColor" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          fill="#f4f3ef"
        />
        <path 
          d="M5 17.5002H3.33333C3.11232 17.5002 2.90036 17.4124 2.74408 17.2561C2.5878 17.0998 2.5 16.8879 2.5 16.6669V11.6669C2.5 11.4458 2.5878 11.2339 2.74408 11.0776C2.90036 10.9213 3.11232 10.8335 3.33333 10.8335H5C5.22101 10.8335 5.43297 10.9213 5.58926 11.0776C5.74554 11.2339 5.83333 11.4458 5.83333 11.6669V16.6669C5.83333 16.8879 5.74554 17.0998 5.58926 17.2561C5.43297 17.4124 5.22101 17.5002 5 17.5002Z" 
          stroke="currentColor" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          fill="#f4f3ef"
        />
        <path 
          d="M10.8335 14.1666H12.071C12.7685 14.1666 13.3335 13.6016 13.3335 12.9041V12.6516C13.3335 12.0724 12.9393 11.5674 12.3777 11.4274L10.4677 10.9499C9.93866 10.8174 9.38778 10.797 8.85044 10.8902C8.31311 10.9833 7.80121 11.1879 7.34767 11.4907L5.8335 12.4999" 
          stroke="currentColor" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
          fill="#f4f3ef"
        />
      </g>
    </svg>
  );
};

export default PayIcon; 