import React from 'react';

const EntitiesIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Short end group (left side) - at the bottom */}
      <path 
        className={`short-end ${isAnimating ? 'animate' : ''}`}
        d="M2.5 16V5.16667C2.5 4.72464 2.67559 4.30072 2.98816 3.98816C3.30072 3.67559 3.72464 3.5 4.16667 3.5H12.5C12.942 3.5 13.366 3.67559 13.6785 3.98816C13.9911 4.30072 14.1667 4.72464 14.1667 5.16667V16"
        stroke="currentColor" 
        strokeWidth="1.5"
      />

      {/* Long end group (right side container) - above short end */}
      <g className={`long-end ${isAnimating ? 'animate' : ''}`}>
        <path 
          d="M5.8335 16.5V3.66667C5.8335 3.22464 6.0091 2.80072 6.32166 2.48816C6.63422 2.17559 7.05814 2 7.50017 2H15.8335C16.2755 2 16.6995 2.17559 17.012 2.48816C17.3246 2.80072 17.5002 3.22464 17.5002 3.66667V16.5" 
          stroke="currentColor" 
          strokeWidth="1.5"
          fill="#f4f3ef"
        />
      </g>

      {/* Connector - above long end */}
      <g className={`connector ${isAnimating ? 'animate' : ''}`}>
        <path d="M4.16667 17H15.8333C16.2754 17 16.6993 16.8244 17.0118 16.5118C17.3244 16.1993 17.5 15.7754 17.5 15.3333V14.5M4.16667 17C4.60869 17 5.03262 16.8244 5.34518 16.5118C5.65774 16.1993 5.83333 15.7754 5.83333 15.3333V14.5M4.16667 17C3.72464 17 3.30072 16.8244 2.98816 16.5118C2.67559 16.1993 2.5 15.7754 2.5 15.3333V14.5" stroke="currentColor" strokeWidth="1.5"/>
      </g>

      {/* Lines group - on top */}
      <g className={`long-end-lines ${isAnimating ? 'animate' : ''}`}>
        <path d="M8.75 5.33325H14.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 8.6665H14.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 12H14.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

export default EntitiesIcon; 