import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import EmployerSidebar from '../layouts/employer-sidebar';
import UnderMaintenance from '../static-components/under-maint';
import ClosedEyeIcon from '../assets/icons/closed-eye-icon.svg';
import RightArrowIcon from '../assets/icons/right-arrow-icon.svg';
import './employer-dashboard.css';
import { useFinchConnect } from '@tryfinch/react-connect';
import { useAuth } from '../contexts/AuthContext';
import { supabaseService } from '../services/supabase-service';

function ConnectPayrollContent({ isFinchLoaded, open }) {
  return (
    <div className="connect-payroll-main">
      <div className="connect-payroll-main-header">
        <div className="connect-payroll-title">Connect your payroll</div>
      </div>
      <div className="connect-payroll-details">
        <div className="connect-payroll-detail-card">
          <button 
            onClick={() => open()}
            className="finch-connect-button"
          >
            Connect Payroll Provider
          </button>
        </div>
        <div className="connect-payroll-detail-description">
          To connect your payroll, please provide your payroll provider's details.
        </div>
      </div>
    </div>
  );
}

function DashboardContent({ businessProfile }) {
  return (
    <div className="employer-dashboard-main-container">
      <div className="employer-dashboard-header-section">
        <div className="employer-dashboard-welcome-text">Welcome, {businessProfile.business_name}</div>
      </div>
      <div className="employer-dashboard-content-grid">
        {/* Enrollment Overview Card */}
        <div className="employer-dashboard-card">
          <div className="employer-dashboard-card-content">
            <div className="employer-dashboard-card-header">
              <div className="employer-dashboard-card-title">Enrollment overview</div>
            </div>
            <div className="employer-dashboard-enrollment-stats">
              <div className="employer-dashboard-stats-header">
                <span>Account participation</span>
                <span>80/100</span>
              </div>
              <div className="employer-dashboard-progress-bar">
                <div className="employer-dashboard-progress-filled" style={{ width: '80%' }}>
                  <span className="employer-dashboard-progress-text">80%</span>
                </div>
                <div className="employer-dashboard-progress-remaining"></div>
              </div>
            </div>
          </div>
          <div className="employer-dashboard-card-footer">
            <div className="employer-dashboard-footer-text">Manage enrollment</div>
            <img src={RightArrowIcon} alt="arrow" />
          </div>
        </div>

        {/* Funding Overview Card */}
        <div className="employer-dashboard-card">
          <div className="employer-dashboard-funding-content">
            <div className="employer-dashboard-card-header">
              <div className="employer-dashboard-card-title">Funding overview</div>
            </div>
            <div className="employer-dashboard-funding-details">
              <div className="employer-dashboard-funding-row">
                <span>Replenishment Amount</span>
                <span>$0.00</span>
              </div>
              <div className="employer-dashboard-funding-box">
                <div className="employer-dashboard-funding-item">
                  <div className="employer-dashboard-funding-label">
                    <div>Required reserve amount</div>
                    <div className="employer-dashboard-funding-sublabel">(reserve % x total elections and carryover)</div>
                  </div>
                  <div>$159,999.00</div>
                </div>
                <div className="employer-dashboard-funding-item">
                  <div className="employer-dashboard-funding-label">
                    <div>Reserve balance</div>
                    <div className="employer-dashboard-funding-sublabel">(funds in your account)</div>
                  </div>
                  <div>$159,999.00</div>
                </div>
                <div className="employer-dashboard-funding-divider"></div>
                <div className="employer-dashboard-funding-item">
                  <div className="employer-dashboard-funding-label">
                    <div>Replenishment amount</div>
                    <div className="employer-dashboard-funding-sublabel">(to maintain required reserve amount)</div>
                  </div>
                  <div>$0.00</div>
                </div>
              </div>
            </div>
          </div>
          <div className="employer-dashboard-card-footer">
            <div className="employer-dashboard-footer-text">Manage funding</div>
            <img src={RightArrowIcon} alt="arrow" />
          </div>
        </div>

        {/* Savings Overview Card */}
        <div className="employer-dashboard-card">
          <div className="employer-dashboard-savings-content">
            <div className="employer-dashboard-card-header">
              <div className="employer-dashboard-card-title">Savings overview</div>
            </div>
            <div className="employer-dashboard-savings-stats">
              <div className="employer-dashboard-stats-header">
                <span>Total contributions</span>
                <span>$100,000.00</span>
              </div>
              <div className="employer-dashboard-savings-visualization">
                <div className="employer-dashboard-savings-bar">
                  <div className="employer-dashboard-savings-primary" style={{ width: '70%' }}>
                    <span className="employer-dashboard-savings-text">80%</span>
                  </div>
                  <div className="employer-dashboard-savings-secondary" style={{ width: '25%' }}></div>
                  <div className="employer-dashboard-savings-tertiary" style={{ width: '5%' }}></div>
                </div>
                <div className="employer-dashboard-savings-legend">
                  <div className="employer-dashboard-legend-item">
                    <div className="employer-dashboard-legend-dot primary"></div>
                    <div className="employer-dashboard-legend-text">You helped your employees save $$$ in income tax this year</div>
                  </div>
                  <div className="employer-dashboard-legend-item">
                    <div className="employer-dashboard-legend-dot secondary"></div>
                    <div className="employer-dashboard-legend-text">You helped your employees save $$ in FICA tax this year</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="employer-dashboard-card-footer">
            <div className="employer-dashboard-footer-text">View contributions</div>
            <img src={RightArrowIcon} alt="arrow" />
          </div>
        </div>

        {/* Notifications Card */}
        <div className="employer-dashboard-card">
          <div className="employer-dashboard-notifications-content">
            <div className="employer-dashboard-card-header">
              <div className="employer-dashboard-card-title">Notifications</div>
            </div>
            <div className="employer-dashboard-notifications-list">
              {[1, 2, 3].map((item) => (
                <div key={item} className="employer-dashboard-notification-item">
                  <div className="employer-dashboard-notification-icon"></div>
                  <div className="employer-dashboard-notification-text">
                    <div>Get a head start on your taxes this year</div>
                    <div className="employer-dashboard-notification-subtext">(to maintain required reserve amount)</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="employer-dashboard-card-footer">
            <div className="employer-dashboard-footer-text">View all</div>
            <img src={RightArrowIcon} alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
}

// Loading component for the main content area
function LoadingContent() {
  return (
    <div className="employer-dashboard-loading">
      <div className="employer-dashboard-loading-spinner"></div>
    </div>
  );
}

function EmployerDashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isFinchLoaded, setIsFinchLoaded] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        console.log('No user found, redirecting to login');
        navigate('/login');
        return;
      }

      try {
        console.log('Fetching business profile for user ID:', user.id);
        const { data, error } = await supabaseService.getBusinessProfile(user.id);
        
        // If there's a profile, stay on the dashboard
        if (data) {
          console.log('Found existing profile:', data);
          setBusinessProfile(data);
          // If we're on the signup page but have a profile, redirect to dashboard
          if (location.pathname === '/employer/signup') {
            navigate('/employer/dashboard');
          }
        } else {
          // ONLY redirect to signup if we don't have a profile AND we're not already on signup
          if (!location.pathname.includes('/signup')) {
            console.log('No profile found, redirecting to signup');
            navigate('/employer/signup');
          }
        }
        
        setError(null);
      } catch (err) {
        console.error('Error checking profile:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      checkAuth();
    }
  }, [user, navigate, location.pathname]);

  const { open } = useFinchConnect({
    clientId: '96af0e91-d67a-451b-81a0-1fa7294a1448',
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    sandbox: true,
    onSuccess: ({ code }) => {
      console.log('Success! Authorization code:', code);
    },
    onError: ({ errorMessage }) => {
      console.error('Finch Connect error:', errorMessage);
    },
    onClose: () => {
      console.log('User closed Finch Connect');
    },
  });

  return (
    <div className="employer-dashboard-container">
      <EmployerSidebar 
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      <div className="employer-dashboard-main">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          // Rest of your component JSX
          <Routes>
            <Route path="/" element={<DashboardContent businessProfile={businessProfile} />} />
            <Route path="/profile" element={<UnderMaintenance pageName="Profile" />} />
            <Route path="/wallet" element={<UnderMaintenance pageName="Wallet" />} />
            <Route path="/transfer" element={<UnderMaintenance pageName="Transfer" />} />
            <Route path="/pay" element={<UnderMaintenance pageName="Pay" />} />
            <Route path="/entities" element={<ConnectPayrollContent isFinchLoaded={isFinchLoaded} open={open} />} />
            <Route path="/help" element={<UnderMaintenance pageName="Help Center" />} />
            <Route path="/docs" element={<UnderMaintenance pageName="Documentation" />} />
            <Route path="/dev" element={<UnderMaintenance pageName="Developer" />} />
            <Route path="/rewards" element={<UnderMaintenance pageName="Rewards" />} />
            <Route path="/settings" element={<UnderMaintenance pageName="Settings" />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default EmployerDashboard;
