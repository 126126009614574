import React from 'react';

const WalletIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M5.75 3C3.67893 3 2 4.67893 2 6.75V7.75L3.5 6.75C3.5 5.50736 4.50736 4.5 5.75 4.5H14.75C15.9926 4.5 17 5.50736 17 6.75V8H14.75V9.5H17V13.25C17 14.4926 15.9926 15.5 14.75 15.5V17C16.8211 17 18.5 15.3211 18.5 13.25V6.75C18.5 4.67893 16.8211 3 14.75 3H5.75Z" 
        fill="currentColor"
      />
      <path 
        className={`wallet-animate-path ${isAnimating ? 'animate' : ''}`}
        d="M2.75 6.75C2.75 5.09315 4.09315 3.75 5.75 3.75H14.75C16.4069 3.75 17.75 5.09315 17.75 6.75V13.25C17.75 14.9069 16.4069 16.25 14.75 16.25H5.75C4.09315 16.25 2.75 14.9069 2.75 13.25V6.75Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        fill="#f4f3ef"
      />
      <path 
        className={`wallet-animate-path ${isAnimating ? 'animate' : ''}`}
        d="M14.5 11C15.0523 11 15.5 10.5523 15.5 10C15.5 9.44772 15.0523 9 14.5 9C13.9477 9 13.5 9.44772 13.5 10C13.5 10.5523 13.9477 11 14.5 11Z" 
        stroke="currentColor" 
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default WalletIcon; 