import React from 'react';

const BalanceGraph = ({ data, width, height }) => {
  // Sample data structure: [{date: timestamp, value: number}, ...]
  if (!data || data.length === 0) return null;

  // Find min and max values for scaling
  const minValue = Math.min(...data.map(d => d.value));
  const maxValue = Math.max(...data.map(d => d.value));
  
  // Create points for the path
  const points = data.map((d, i) => {
    const x = (i / (data.length - 1)) * width;
    const y = height - ((d.value - minValue) / (maxValue - minValue)) * height;
    return `${x},${y}`;
  }).join(' ');

  return (
    <svg className="balance-graph-background" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
      <path
        d={`M0,${height} L${points} L${width},${height}`}
        fill="#17332D"
        fillOpacity="0.5"
      />
      <path
        d={`M${points}`}
        stroke="#17332D"
        strokeWidth="2"
        fill="none"
      />
    </svg>
  );
};

export default BalanceGraph; 
