import React from 'react';

const HomeIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M16.535 6.91661L11.535 3.02661C11.0961 2.68523 10.556 2.49988 10 2.49988C9.444 2.49988 8.90386 2.68523 8.465 3.02661L3.465 6.91578C3.16463 7.14943 2.92157 7.44858 2.75434 7.79041C2.58711 8.13224 2.50012 8.50773 2.5 8.88828V14.9999C2.5 15.663 2.76339 16.2989 3.23223 16.7677C3.70107 17.2366 4.33696 17.4999 5 17.4999H15C15.663 17.4999 16.2989 17.2366 16.7678 16.7677C17.2366 16.2989 17.5 15.663 17.5 14.9999V8.88828C17.4998 8.50788 17.4127 8.13254 17.2455 7.79087C17.0783 7.44919 16.8353 7.15018 16.535 6.91661Z" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className={`home-line-1 ${isAnimating ? 'animate' : ''}`}
        d="M7.91666 9.99988H12.0833" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        className={`home-line-2 ${isAnimating ? 'animate' : ''}`}
        d="M7.91666 13.3333H12.0833" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon; 