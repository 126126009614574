import React from 'react';

const UserIcon = ({ isAnimating }) => {
  return (
    <svg 
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        className={`user-animate-path ${isAnimating ? 'animate' : ''}`}
        d="M13.5 6.5C13.5 8.70914 11.9591 10.5 9.75 10.5C7.54086 10.5 6 8.70914 6 6.5C6.25 4.29086 7.98411 3 9.75 3C11.5159 3 13.25 4.29086 13.5 6.5Z" 
        stroke="currentColor" 
        strokeWidth="1.5"
      />
      <path 
        d="M3 16.4C3 14.5222 4.52223 13 6.4 13H13.1C14.9778 13 16.5 14.5222 16.5 16.4V17C16.5 17.5523 16.0523 18 15.5 18H4C3.44772 18 3 17.5523 3 17V16.4Z" 
        stroke="currentColor" 
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default UserIcon; 